@import '../../styles/global';

@mixin MultiRangeSlider {
	$blue: #2172E5;

	.multi-range-slider {
		background-color: none;
		border: none;
		box-shadow: none;
		padding: 1.5rem;
		border-radius: 0.25rem;

		.bar {
			.bar-left,
			.bar-right {
				box-shadow: none;
				background-color: $gray-400;
			}

			.bar-inner {
				background-color: $blue;
				box-shadow: none;
				border: 0;
			}

			.thumb {
				// value popup onslide
				.min-value,
				.max-value {
					background-color: $blue;
					height: 25px;
					// width: 30px;
					width: fit-content;
					padding: 0 .5rem;
					bottom: 15px;
					border-radius: .25rem;
					font-size: 12px;
					line-height: 25px;
					box-shadow: $box-shadow;
					-webkit-appearance: none;
				}

				&:before {
					box-shadow: none;
					background-color: $gray-800;
					width: 3px;
					height: 20px;
					border: none;
					border-radius: 0;
					margin: 0;
				}
			}
		}

		.label {
			margin-top: 15px;
		}
	}
}
