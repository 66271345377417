table.table-stripe {
	$background-color: $gray-100;
	$border: 1px solid $gray-200;
	$padding: 1.25rem;
	$transition: background-color .15s ease;

	margin-bottom: 0;

	* {
		&[class^=border] {
			border-color: $gray-200 !important;
		}
	}

	thead {
		tr {
			th {
				color: $gray-600;
				background-color: $background-color;
				border-top: $border;
				border-bottom: $border;
				padding-left: $padding;
				padding-right: $padding;
				font-size: .7rem;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
				white-space: nowrap;
				padding-top: .5rem;
				padding-bottom: .5rem;

				&.hasSort {
					cursor: pointer;
					transition: $transition;

					&:hover {
						background-color: darken($background-color, 1%);
					}
				}
			}
		}
	}

	tbody {
		tr {
			cursor: pointer;
			transition: $transition;

			&:hover {
				background-color: $background-color;
			}

			&:first-child {
				th {
					border-top: 0;
				}
			}

			th, td {
				vertical-align: middle;
				padding-left: $padding;
				padding-right: $padding;
				font-size: .8125rem;
			}

			td {

			}
		}
	}
}
