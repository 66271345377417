@import '../../../styles/global.scss';

@mixin barStops {
	content: '';
	position: absolute;
	width: 1px;
	height: 2px;
	background-color: $gray-700;
	top: 0;
	z-index: 1;
}

@mixin ProgressBar {
	.progress-bar {
		position: absolute;
		top: 0;
		height: 3px;
		width: 100%;
		background-color: $gray-200;

		&.right {
			border-top-left-radius: .25rem;
			border-bottom-left-radius: .25rem;

			.progress-bar-inner {
				right: 0;
				border-right: 1px solid $gray-100;
			}
		}

		&:not(.right) {
			border-top-right-radius: .25rem;
			border-bottom-right-radius: .25rem;

			.progress-bar-inner {
				left: 0;
			}
		}

		&:before,
		&:after {
			@include barStops;
		}

		&:before { left: 33%; }
		&:after { left: 66%; }

		.progress-bar-inner {
			position: absolute;
			top: 0;
			height: 2px;
			transition: width .3s ease-out;
		}
	}
}

.Positions {
	.card {
		overflow-y: hidden;

		.chain-border-color {
			position: absolute;
			width: 100%;
			height: 2px;
			opacity: 0.3;
		}
	}

	table.table-stripe {
		tbody {
			tr {
				td {
					position: relative;
					@include ProgressBar;
				}
			}
		}
	}
}
