@import '../../../../styles/global.scss';
@import '../Positions.scss';

.PositionsMobile {
	padding: 1.25rem .75rem;

	.Totals {
		position: relative;
		overflow-x: hidden;

		.chain-border-color {
			position: absolute;
			width: 100%;
			height: 2px;
			opacity: 0.3;
			top: 0;
			left: 0;
		}

		.label {
			font-size: .85rem;
			font-weight: 500;
			color: $gray-600;
		}

		.value {
			font-size: 1.1rem;
			font-weight: 500;
			color: $gray-800;
		}
	}

	.Position {
		overflow: hidden;
		@include ProgressBar;

		.progress-bar {
			top: unset;
			bottom: 0;
			width: 50%;
			border-radius: 0 !important;

			&:not(.right) {
				left: 50%;
			}

			.progress-bar-inner {
				height: 3px;
			}
		}
	}
}
