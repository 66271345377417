@import '../../../../../styles/global';

@mixin PositionPercentages {
	.PositionPercentages {
		width: 100%;
		border-top: 1px solid $gray-300;
		margin-top: 1rem;
		padding-top: .75rem;

		.range-container {
			.edge-range-container {
				min-width: fit-content;
				max-width: fit-content;
				flex-grow: 1;
			}

			.border-line-between {
				flex-grow: 0;
				width: 100%;
				height: 1px;
				background-color: $gray-300;
			}
		}
	}
}

.MyPositionInfo {
	&.d-none {
		display: none !important;
	}

	.PriceContainer {
		max-width: fit-content;
	}

	.TicksInBetween {
		width: 100%;

		.price {
			line-height: 15px;
		}

		.price-progress-container {
			margin: 5px 0;
			width: 100%;
			height: 5px;
			border-top: 1px solid $gray-400;
			border-bottom: 1px solid $gray-400;
			background-color: lighten($gray-200, 3%);

			.price-progress {
				max-width: 100%;
				height: 3px;
				border-top-right-radius: .25rem;
				border-bottom-right-radius: .25rem;
				transition: width .3s ease-out;
			}
		}


		.tick {
			width: 1px;
			height: 15px;
			background-color: $gray-400;

			&.hide {
				opacity: 0;
			}

			&.current {
				width: 2px;
				background-color: lighten($success, 10%);
			}
		}

		.no-ticks {
			line-height: 15px;
		}
	}

	@include PositionPercentages;
}
