@import '../../../../styles/global';

@mixin truncate {
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

$bg-transition: background-color .15s ease;

$SidebarWidth: 48px;
$DexSelectorHeight: 50px;
$PreHeaderHeight: 55px;

.Sidebar {
	min-width: $SidebarWidth;
	padding: .5rem 0;

	align-self: flex-start;
	position: sticky;
	top: 0;

	.divider {
		width: 34px;
		height: 1px;
		background-color: $gray-300;
		margin: 1.5rem auto .5rem;
	}

	.logo-container {
		cursor: pointer;
		width: 34px;
		height: 34px;
		padding: 4px;
		text-align: center;

		border: 1px solid white;
		border-radius: .25rem;

		transition: .1s background-color ease, .1s box-shadow ease, .1s border-color ease;

		img {
			width: 100%;
			height: 100%;
			vertical-align: top;
		}

		&.active {
			border-color: $gray-400;
			background-color: white;
			box-shadow: $box-shadow-sm;
		}
	}

	.ListsSelector {
		.logo-container {

		}
	}
}

.DexSelector {
	list-style-type: none;

  display: flex;
  align-items: center;

  max-width: 100vw;
  min-height: $DexSelectorHeight;
  height: $DexSelectorHeight;
  overflow: auto hidden;

  margin-bottom: 0;
  padding: 0 .5rem;
  background: white;

  // position: sticky;
  // top: 0;
  z-index: 4;

  li {
  	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  	transition-duration: 150ms;
  	transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

  	flex-shrink: 0;
  	display: flex;
  	align-items: center;
  	position: relative;
  	cursor: pointer;
    text-decoration: none;
    outline: transparent solid 2px;
    outline-offset: 2px;
    height: $DexSelectorHeight - 1;
    border-radius: .375rem;
    padding: 0 .5rem;

  	&:not(:first-child) {
  		margin-left: .5rem;
  	}

  	&:after {
  		position: absolute;
	    bottom: -3px;
	    left: 0px;
	    content: "";
	    width: 100%;
	    height: 5px;
	    border-radius: 10px;
	    background: transparent;
	    transition: background-color 0.2s ease 0s;
  	}

  	&:hover {
  		&:after {
  			background: $gray-200;
  		}
  	}

  	&.active {
  		&:after {
  			position: absolute;
		    bottom: -3px;
		    left: 0px;
		    content: "";
		    width: 100%;
		    height: 5px;
		    border-radius: 10px;
		    background: $gray-500;
		    transition: background-color 0.2s ease 0s;
  		}
  	}
  }

  @media screen and (min-width: 62em) {
  	flex-wrap: wrap;
    // max-width: calc(100vw - 210px);
    padding-right: 110px;
  }
}

.Search {
	border-radius: 0 !important;

	&:focus {
		box-shadow: none !important;
	}
}

.Preheader {
	display: grid;
	grid-template-columns: 40vw 11fr 30fr 15fr 17fr;

	height: $PreHeaderHeight;
	padding-top: .75rem;
	background-color: $gray-100;
	font-size: .8125rem;

	// position: sticky;
	// top: $DexSelectorHeight;
	// z-index: 3;
	z-index: 2;

	.price-change,
	.activity {
		padding: .25rem .5rem;
		background-color: white;
		border-top: 1px solid $gray-300;
		border-left: 1px solid $gray-300;
		border-right: 1px solid $gray-300;
		border-top-left-radius: .25rem;
		border-top-right-radius: .25rem;
	}

	.summary {
		grid-column: 1 / 3;
		font-size: 11px;
		padding: .25rem .5rem;
	}

	.price-change {
		grid-column: 3 / auto;
	}

	@media screen and (min-width: 48em) {
		grid-template-columns: 38% 30% 15% 17%;

		.summary {
			grid-column: 1 / 2;
			font-size: 13px;
		}

		.price-change {
			grid-column: 2 / auto;
		}
	}
}

.Pools {
	flex-grow: 1;
	width: 100%;
	overflow-x: scroll;
	height: 100%;

	.inner-wrapper {
		// transition: opacity .15s ease;

		&.isFetching {
			// opacity: .5;
		}

		min-width: calc(45vw + 900px);

		.headers,
		.table-row {
			display: grid;
			//                     pool price 5m    1h    6h    24h   txns vol liq   apr
			grid-template-columns: 40vw 11fr  7.5fr 7.5fr 7.5fr 7.5fr 7fr  8fr 7.5fr 9.5fr;
		}

		.headers {
			// position: sticky;
			// top: $DexSelectorHeight;
			z-index: 3;
		}

		.table-row {
			text-decoration: none;
			color: $gray-700;

			&:hover {
				.row-value {
					background-color: $gray-100;
				}
			}
		}

		.header-value,
		.row-value {
			transition: $bg-transition;

			padding: .45rem .5rem;
			border-bottom: 1px solid $gray-300;
			white-space: nowrap;
			overflow-x: hidden;

			&:not(:last-child) {
				border-right: 1px solid $gray-200;
			}

			&:not(:first-child) {
				text-align: right;
			}

			&:first-child {
				position: sticky;
				left: 0px;
				z-index: 2;
			}
		}

		.header-value {
			font-size: .7rem;
			font-weight: 600;
			border-top: 1px solid $gray-300;
			background-color: $gray-100;
			color: $gray-600;
			text-transform: uppercase;
			letter-spacing: 1px;
			cursor: pointer;

			&:hover {
				background-color: darken($gray-100, 1%);
			}
		}

		.row-value {
			font-size: .8125rem;
			background-color: white;

			&.pool-name {
				.copy-link {
					transition: opacity .1s ease, color .1s ease;

					opacity: 0;
					color: lighten($info, 8%);
				}

				&:hover {
					.copy-link {
						opacity: 1;

						&:hover {
							color: $info;
						}
					}
				}
			}
		}

		@media screen and (min-width: 48em) {
			min-width: 1040px;

			.headers,
			.table-row {
				// 										 pool price 5m   1h   6h   24h  txns vol liq  apr
				grid-template-columns: 27%  11%   7.5% 7.5% 7.5% 7.5% 7% 8%  7.5% 9.5%;
			}
		}
	}
}

#PoolsWrapper {
	width: 100vw;
	border-top: 1px solid $gray-200;

	#PoolsMain {
		width: 100%;
	}

	@media screen and (max-width: 991px) {
		.Sidebar {
			display: none !important;
		}
	}

	@media screen and (min-width: 992px) {
		background-color: white;
		border-bottom: 1px solid $gray-200;

		#PoolsMain {
			border-left: 1px solid $gray-200;
			width: calc(100vw - #{$SidebarWidth});
		}
	}
}
