@import '../../styles/global';
@import '../../components/MultiRangeSlider/MultiRangeSlider.scss';
@import '../../components/RcSlider/RcSlider.scss';

@mixin Config {
	.Config {
		width: 100%;
		// max-width: 600px;
		margin: 0 auto;

		.label {
			font-size: 0.8rem;
			color: $gray-600;
			margin-bottom: 0.25rem;
			padding-left: .25rem;
		}

		.TokenDropdownContainer {
			min-width: 90px;

			.TokenDropdown {
				width: 100%;

				button {
					width: 100%;
				}
			}
		}

		.ChainSelectorDropdown {
			.dropdown {
				.dropdown-item {
					text-transform: capitalize;
				}
			}
		}
	}
}

@mixin PoolInfo {
	.PoolInfo {
		font-size: .85rem;
		box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
		border: none;

		.title-container {
			.dex {
				text-transform: capitalize;
				color: $gray-800;
			}

			.fee {
				font-size: .75rem;
			}

			.price {
				color: $success;
				font-weight: 500;
			}
		}

		.info-container {
			.title {
				font-size: 0.85rem;
				font-weight: 500;
				color: $gray-600;
			}

			.info-item {
				&:not(:first-child):not(:last-child) {
					text-align: center;
				}

				&:last-child {
					text-align: right;
				}

				.label {
					font-size: 12px;
					line-height: 11px;
					font-weight: 500;
					color: $gray-500;
					text-transform: uppercase;
				}

				.value {
					margin-top: .25rem;
					font-weight: 600;
					color: $gray-700;
				}
			}
		}
	}
}

.Analyze {
	@include Config;
	@include PoolInfo;

	.UniswapTickSelector {
		@include MultiRangeSlider;

		.rc-slider-wrapper {
			padding: 1.5rem 20px;
			@include RcSlider;
		}

		.multi-range-slider {
			padding-left: 20px;
			padding-right: 20px;
		}

		.label-container {
			padding: 0 20px .5rem;
			margin-top: -10px;
			font-size: .9rem;
			font-weight: 500;
			color: $gray-700;

			.tick {
				font-size: .8rem;
				color: $gray-600;
			}
		}
	}
}
