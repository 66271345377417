@import '../../styles/global';

.Home {
	.links-grid {
		width: 100%;
		max-width: 880px;;
		display: grid;
		gap: 16px;
		grid-template-columns: repeat(4, 1fr);

		.grid-link-item {
			height: 240px;
			border-radius: 24px;
	    display: flex;
	    flex: 1 1 0%;
	    flex-direction: column;
	    overflow: hidden;
	    position: relative;
	    width: 100%;
	    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15) !important;

	    will-change: transform;
			transform: translate3d(0px, 0px, 0px) scale(1);
			transition: transform .25s ease-out;

			background: linear-gradient(rgb(215, 215, 215) 0%, rgb(192, 192, 192) 100%);

			&[item="uniswap"] {
				background: linear-gradient(rgb(255, 242, 248) 0%, rgb(249, 203, 224) 100%);
			}

			&[item="monitor"] {
				background: linear-gradient(rgb(204, 211, 255) 0%, rgb(151, 165, 245) 100%);
			}

			.title-container {
				display: flex;
				justify-content: center;
				align-items: center;

				text-align: center;
				width: 100%;
				height: 100%;
				max-height: 60px;

				.title {
					font-size: 1.25rem;
					color: $gray-700;
					margin-bottom: 0;
				}
			}

			.logo-container {
				width: 100%;
				min-height: 180px;
				display: flex;
				justify-content: center;
				align-items: center;

				.logo, .font-logo {
			    will-change: transform;
					transform: translate3d(0px, 0px, 0px) scale(1);
					transition: transform .25s ease-out, color .25s ease-out;
				}

				.logo {
					width: auto;
					height: 100%;
				}

				.font-logo {
					font-size: 4.5rem;
				}
			}

			&:hover {
				text-decoration: none;
				transform: translate3d(0px, 0px, 0px) scale(.99);

				.logo-container {
					.logo, .font-logo {
						transform: translate3d(0px, 0px, 0px) scale(1.03);
					}
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		.links-grid {
			grid-template-columns: repeat(2, 1fr);

			.grid-link-item {
				height: 200px;
				justify-content: space-between;

				.title-container {
					.title {
						font-size: 1rem;
					}
				}

				.logo-container {
					min-height: 130px;
				}
			}
		}
	}
}
