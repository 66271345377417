@import '../../styles/global.scss';

$purple: #f6f6ff;

@mixin TokenDropdown {
	.TokenDropdown {
		min-width: 132px;
		max-width: 40%;

		button {
			height: 100%;
			width: 100%;
			padding-left: 0;
			text-align: left;

			border: none;
			font-size: 1.15rem;
			font-weight: 500;
			color: $gray-800;

			&:hover,
			&:active,
			&:focus,
			&:focus-visible {
				outline: none;
				box-shadow: none;
			}
		}
	}
}

@mixin TokenQuantityInput {
	.TokenQuantityInput {
		height: 64px;
		padding-bottom: 26px;
		border: 1px solid transparent;
		border-radius: 0.5rem;
		font-weight: 600;
		font-size: 1.2rem;
		text-align: right;
		background-color: $purple;

		&.red-border {
			border: 1px solid rgb(226, 46, 46);
			box-shadow: rgba(4, 8, 106, .07) 0px 2px 2px inset;
		}

		&:not(.red-border) {
			&:hover {
				border: 1px solid rgb(223, 223, 241);
				box-shadow: rgba(4, 8, 106, .07) 0px 2px 2px inset;
			}

			&:focus {
				border: 1px solid rgb(112, 110, 255);
				outline: none;
				box-shadow: none;
			}
		}
	}
}

@mixin SwapInputContainer {
	.SwapInputContainer {
		position: relative;
		border-radius: 0.5rem;
		padding: 0.75rem 0rem;

		.header {
			font-size: .9rem;
			font-weight: 500;
			color: $gray-700;

			.you-pay {
			}

			.max {
				font-size: .8rem;
				cursor: pointer;
				color: $gray-600;
				font-weight: 400;
			}
		}

		@include TokenDropdown;
		@include TokenQuantityInput;

		.value-in-dai {
			position: absolute;
			bottom: 22px;
			right: 14px;
			text-align: right;
			font-size: .8rem;
			font-weight: 400;
			color: $gray-600;

			.value {

			}

			.slippage-from-trade {

			}
		}

		&:not(.isFirst) {
			.header {
				margin-top: 1.5rem;
			}
		}
	}
}

@mixin SwitchDirection {
	.SwitchDirection {
		width: calc(100% + 1.5rem);
		height: 1px;
		background-color: $gray-200;
		margin: 1.5rem 0 -.25rem -0.75rem;
		position: relative;
		z-index: 1;

		.SwitchDirectionButton {
			* {
				transform: rotate(0deg);
				will-change: transform;
				transition: transform .5s ease;

				&.rotate {
					transform: rotate(360deg);
				}
			}

			position: absolute;
			width: 34px;
			height: 34px;
			top: -16px;

			border: 1px solid $gray-300;
			background-color: white;
			border-radius: 50%;
			font-size: 1rem;
			color: $gray-700;
		}
	}
}

@mixin LiqProviderAndFee {
	.LiqProviderAndFee {
		font-size: .95rem;

		.sources {
			.showSourcesIcon {
				margin-left: .25rem;

				transform: rotate(0deg);
				will-change: transform;
				transition: transform .2s ease;

				&.rotate {
					transform: rotate(180deg);
				}
			}
		}

		.sources-details-container {
			width: 100%;
			padding: .5rem;
			font-size: .85rem;
		}
	}
}

.Swap {
	max-width: 540px;

	.card {
		box-shadow: rgba(33,35,74,.17) 0px 22px 48px -9px;
		border: 1px solid rgb(232, 236, 253);
	}

	@include SwapInputContainer;
	@include SwitchDirection;
	@include LiqProviderAndFee;
}
