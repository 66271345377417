@import '../../styles/global';

@mixin popupSizes {
	&[_size=xs] { width: 280px; }
	&[_size=sm] { width: 380px; }
	&[_size=md] { width: 480px; }
	&[_size=lg] { width: 580px; }
	&[_size=xl] { width: 880px; }
}

@mixin animationStyles {
	z-index: 9998;
	display: none;

	opacity: 0;
	transition: opacity .15s ease;

	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.2);

	.popup-container {
		z-index: 9999;
		display: none;

		opacity: 0;
		top: 0;
		transition: opacity .15s ease, top .15s ease;

		position: fixed;
		left: 50%;
		transform: translate(-50%);

		width: 680px;
		max-width: calc(100vw - 20px);
		min-height: 380px;
		max-height: calc(100vh - (96px * 2));
		overflow-y: scroll;

		background-color: white;
		border-radius: .25rem;
		box-shadow: 0 1rem 3rem rgba(0,0,0,0.18) !important;

		@include popupSizes;

		@include media-breakpoint-down(md) {
			top: 100vh;
			box-shadow: 0 .25rem 3rem rgba(0,0,0,0.18) !important;
			width: 100vw;
			max-width: 100vw;
			min-height: calc(100vh - 18px);
			max-height: calc(100vh - 18px);

			border-radius: 1rem 1rem 0 0;
		}
	}

	&.open,
	&.isOpened {
		display: block;

		.popup-container {
			display: block;
		}
	}

	&.isOpened:not(.open):not(.isOpening) {// If only isOpened, then we're closing
		transition-delay: .15s;
		transition-property: opacity;
	}

	&.isOpening {
		opacity: 1;

		.popup-container {
			opacity: 1;
			top: 96px;
			transition-delay: .15s, .15s;
			transition-property: top, opacity;

			@include media-breakpoint-down(md) {
				top: 18px;
			}
		}
	}
}

.Popup {
	@include animationStyles;

	.popup-container {
		.back-btn {
			border-bottom: 1px solid $gray-200;

			.mobile {
				padding: .5rem .75rem;
			}

			.desktop {
				padding: .5rem .75rem .5rem 1rem;
				transition: color .15s ease;

				&:hover {
					color: $info;
				}
			}
		}

		@include media-breakpoint-down(md) {
			padding-bottom: 120px;
		}
	}
}
