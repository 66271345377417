@import '../../../../styles/global';

.TVChartCard {
	overflow: hidden;

	.title-container {
		[class^=fa] {
			transition: color .1s ease;
		}

		&:hover {
			[class^=fa] {
				color: $info;
			}
		}
	}

	.chart-container {
		height: 320px;

		@include media-breakpoint-up(xl) {
			height: 420px;
		}
	}
}
