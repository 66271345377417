@import '../../styles/global.scss';

.NavbarCustom {
	.container {
		.navbar-brand {
			display: flex;
			align-items: center;

			.logo {
				height: 30px;

				@include media-breakpoint-down(sm) {
					height: 20px;
				}
			}
		}
	}
}
