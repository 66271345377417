@import '../../styles/global';

.Toggle {
	background-color: $gray-200;
	border-radius: 6px;
	padding: 2px;
	border: 1px solid $gray-300;

	.Option {
		background-color: $gray-200;
		color: $gray-600;
		border-radius: 4px;
		padding: 1px 5px;

		text-transform: capitalize;
		cursor: pointer;

		transition: background-color .15s ease, color .15s ease;

		&:hover {
			background-color: $gray-100;
		}

		&:not(:last-child) {
			margin-right: 3px;
		}

		&.active {
			background-color: white;
			color: $gray-800;
		}
	}
}
