@import 'rc-slider/assets/index.css';
@import '../../styles/global';

@mixin RcSlider {
	$blue: #2172E5;

	.rc-slider {
		.rc-slider-rail {
			background-color: $gray-400;
			height: 8px;
		}

		.rc-slider-track {
			background-color: $blue;
			height: 8px;
			border-radius: 0;
		}

		.rc-slider-step {
			height: 8px;
		}

		.rc-slider-handle {
			background-color: $gray-800;
			width: 3px;
			height: 20px;
			border: none;
			border-radius: 0;
			margin-top: 0;
			opacity: 1;

			&.rc-slider-handle-dragging,
			&:hover,
			&:active,
			&:focus,
			&:focus-visible {
				box-shadow: none;
				outline: none;
			}
		}
	}
}
