.Logo {
	width: 20px;
	height: 20px;
	background:
		rgba(0, 0, 0, 0) radial-gradient(
			white 60%,
			rgba(255, 255, 255, 0) calc(75% + 1px),
			rgba(255, 255, 255, 0) 100%
		) repeat scroll 0% 0%;
	border-radius: 50%;
	box-shadow: black 0px 0px 1px;
	border: 0px solid rgba(255, 255, 255, 0);

	&.pullLeft {
		margin-left: -6px;
	}

	&.padLeft {
		margin-left: 5px;
	}

	&.isDex {
		border-radius: .25rem;
		padding: 2px;
	}

	&.not-found {

	}
}
