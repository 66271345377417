@import '../../styles/global.scss';
@import '../analyze/Analyze.scss';
@import '../uniswap/Position/TickChart/MyPositionInfo/MyPositionInfo.scss';

@mixin TokenSelector {
	.TokenSelector {
		align-items: flex-start;
		display: grid;
		grid-auto-flow: column;
		gap: 8px;
		grid-auto-columns: 2fr;
	}
}

@mixin FeeTierSelector {
	.FeeTierSelector {
		.fee-selector-wrapper {
			align-items: flex-start;
			display: grid;
			grid-auto-flow: column;
			gap: 8px;
			grid-auto-columns: 4fr;

			.tier {
				width: 100%;
				padding: .5rem .5rem;
				border: 1px solid $gray-200;
				border-radius: .5rem;
				font-size: 0.9rem;
				cursor: pointer;
				transition: border-color .15s ease, background-color .15s ease;

				&:hover {
					border-color: $gray-400;
				}

				&.active {
					border-color: $primary;
				}
			}
		}

		&.disabled {
			opacity: 0.65;

			.tier {
				cursor: not-allowed;
			}
		}
	}
}

@mixin DepositAmounts {
	.DepositAmounts {
		.actions {
			.ratio-lock {
				font-size: .75rem;
				margin-top: -4px;
				padding: 0 .25rem;
				color: $gray-700;
				cursor: pointer;
			}

			.all-balance {
				padding-left: .25rem;
				cursor: pointer;
			}
		}

		.DepositAmountQuantityInput {
			padding: .5rem 1rem .75rem;
			background-color: $gray-200;
			border: 1px solid $gray-400;
			border-radius: .5rem;

			@media only screen and (max-width : 991px) {
				padding: .5rem .75rem .75rem;
			}

			.input-and-token-logo {
				input {
					padding-left: 0;
					width: 100%;
					margin-right: .5rem;
					margin-top: -1px;
					background-color: transparent;
					border: none;
					font-size: 1.4rem;
					-moz-appearance: textfield;
					&::-webkit-outer-spin-button,
					&::-webkit-inner-spin-button {
					  -webkit-appearance: none;
					  margin: 0;
					}

					&:focus {
						border: none;
						outline: none;
					}
				}

				.logo-wrapper {
					height: 34px;
					width: fit-content;
					padding: .5rem;
					border-radius: 1rem;
					background-color: white;
					white-space: nowrap;
				}
			}

			.usd-value-and-balance {
				.usd-value {
					font-size: .8rem;
					font-weight: 500;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-right: .5rem;
				}

				.balance {
					max-width: 142px;
					font-size: .8rem;
					font-weight: 500;
					text-align: right;
					margin-top: .25rem;
					padding-right: .25rem;
					cursor: pointer;
					color: black;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		&.disabled {
			opacity: 0.65;

			.actions {
				.ratio-lock,
				.all-balance {
					cursor: not-allowed;
				}
			}

			.DepositAmountQuantityInput {
				input, .logo-and-balance, .logo-wrapper, .balance {
					cursor: not-allowed;
				}
			}
		}
	}
}

@mixin PoolInfoAndPriceRangeSelectors {
	.PoolInfoAndPriceRangeSelectors {
		user-select: none;

		@media only screen and (max-width : 991px) {
			flex-direction: column-reverse;
		}

		.PoolInfoContainer {
		}

		.TickSelectorBoxes {
			.TickSelectorBox {
				user-select: none;

				.tick-modify-btn {
					padding: .25rem .35rem;
					background-color: $gray-200;
					border-radius: 0.25rem;
					cursor: pointer;
					transition: background-color 0.15s ease;

					&:hover {
						background-color: $gray-300;
					}
				}
			}
		}

		@include PositionPercentages; //MyPositionInfo.scss
	}
}

@mixin MyPositionsForTickSelection {
	.MyPositionsForTickSelection {
		&:not(.borderBottom) {
			border-top: 1px solid $gray-300;
			margin-top: 1rem;
			padding-top: .75rem;
		}

		&.borderBottom {
			border-bottom: 1px solid $gray-300;
			margin-bottom: .5rem;
			padding-bottom: .75rem;
		}

		.positions-container {
			overflow-x: scroll;
			margin-top: .25rem;
			padding: .25rem 0;

			.position {
				border: 1px solid $gray-200;
				border-radius: .25rem;
				padding: .25rem .75rem;
				margin: 0 .25rem;
				cursor: pointer;
				box-shadow: none;
				transition: box-shadow 0.15s ease, border-color 0.15s ease;

				&:hover {
					box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,0.08);
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}

				&.active {
					border-color: $primary;
				}

				.header {
					font-size: 0.75rem;
					font-weight: 600;
					color: $gray-600;
					margin-right: 0.5rem;
				}

				.InRangeIcon {
					font-size: 0.75rem;
				}

				.value-in-dai {
					font-size: .85rem;
					font-weight: 500;
				}

				.position-range {
					font-size: 0.75rem;
					font-weight: 500;
					color: $gray-700;
					border-top: 1px solid $gray-200;
					padding-top: 0.1rem;
					margin-top: 0.1rem;
				}
			}
		}
	}
}

.Liquidity {
	@include TokenSelector;
	@include FeeTierSelector;
	@include DepositAmounts;
	@include PoolInfo; //Analyze.scss
	@include PoolInfoAndPriceRangeSelectors;
	@include MyPositionsForTickSelection;

	.TickChartWrapperTitle {
		@media only screen and (max-width : 991px) {
			margin-top: 1.5rem;
			padding-top: 1rem;
			border-top: 1px solid $gray-200;
		}

		.reset-selected-tick-range {
			cursor: pointer;
		}

		&.disabled {
			opacity: 0.65;

			.reset-selected-tick-range {
				cursor: not-allowed;
			}
		}
	}
}
