@import '../../styles/global';

.RefreshBtn {
	position: relative;
	outline: none;
	border: none !important;
	border-radius: .25rem;
	box-shadow: none;
	background-image:
		linear-gradient(#dee2e6, #dee2e6),
	  linear-gradient(#dee2e6, #dee2e6),
	  linear-gradient(#dee2e6, #dee2e6),
	  linear-gradient(#dee2e6, #dee2e6);
	background-position: 0 0, 0 0, 0 100%, 0 100%;
  background-size: 2px 0%, 0% 2px, 0% 2px, 2px 0%;
  background-color: transparent;
  background-repeat: no-repeat;

  &:before {
  	content:"";
	  position: absolute;
	  z-index: 0;
	  top: 0;
	  right: 0;
	  left: 0;
	  bottom: 0;
	  border: 1px solid #dee2e6;
	  border-radius: .25rem;
  }

  &.border-animate {
  	&:before {
  		border: none;
  	}

  	background-position:
  		0 100%, //left   [x y]. Start at y bottom
  		0 0, 		//top 	 [x y]. Start at top left corner
  		100% 0, //right  [x y]. Start at x all way to right, y at top
  		100% 100%; 		//bottom [x y].
	  background-size: //Note this is the final step at 100%. Initial stage is set at 0% in animation
	  	2px 100%, //left 	 [width height]. y is at bottom so 0% height -> 100% height, last
	  	1000% 2px, //top 	 [width height]. height acts as thickness
	  	2px 100%, //right  [width height].
	  	100% 2px; //bottom [width height]. Start at right width, animate to left (100%)
	  animation: animate ease-out;
  }
}

@keyframes animate {
  0% {
  	background-size: 2px 0%, 0% 2px, 2px 0%, 0% 2px;
  }
  25% {
  	background-size: 2px 0%, 100% 2px, 2px 0%, 0% 2px;
  }
  50% {
  	background-size: 2px 0%, 100% 2px, 2px 100%, 0% 2px;
  }
  75% {
  	background-size: 2px 0%, 100% 2px, 2px 100%, 100% 2px;
  }
  100% {
  	background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  }
}

.__RefreshBtn {
	position: relative;
	vertical-align: middle;

	&:before,
  &:after {
    box-sizing: inherit;
    content: '';
    position: absolute;

    // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
    border: 1px solid transparent;
    border-radius: .25rem;
    width: 0;
    height: 0;
  }

  &:before {
  	// This covers the top & right borders (expands right, then down)
  	top: 0;
  	left: 0;
  }

  &:after {
  	// And this the bottom & left borders (expands left, then up)
  	bottom: 0;
  	right: 0;
  }

	&.border-animate {
		border-color: transparent !important;

		&:before,
	  &:after {
	    width: 100%;
	    height: 100%;
	  }

	  $duration: 5s;

	  &:before {
	  	border-top-color: $secondary; // Make borders visible
	    border-right-color: $secondary;
	    transition:
	    	width $duration ease-out, // Width expands first
	    	height $duration ease-out ($duration*1); // And then height
	  }

	  &:after {
	  	border-bottom-color: $secondary; // Make borders visible
	    border-left-color: $secondary;
	    transition:
	      border-color 0s ease-out ($duration*2), // Wait for ::before to finish before showing border
	      width $duration ease-out ($duration*2), // And then exanding width
	      height $duration ease-out ($duration*3); // And finally height
	  }
	}
}
