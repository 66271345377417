@import '../../../../styles/global';

.Pool {
	flex-direction: column-reverse;

	@media screen and (min-width: 992px) {
		flex-direction: row;
	}
}

.PoolHeader {
	background-color: $gray-100;
	border-bottom: 1px solid $gray-200;

	.dexId {
		text-transform: capitalize;
		font-weight: 400;
	}

	.fee,
	.apr {
		font-size: .8rem;
		font-weight: 500;
	}

	.fee {
		color: $gray-600;
	}

	.apr {
		color: $gray-700;
	}
}

.PoolInfo {
	padding: 1rem;
	overflow-y: scroll;

	.price-info,
	.price-change,
	.txns-container {
		.label {
			font-size: 12px;
			line-height: 11px;
			font-weight: 500;
			color: $gray-500;
			text-transform: uppercase;
		}

		.value {
			margin-top: .25rem;
			font-weight: 600;
			color: $gray-700;
		}
	}

	.txns-container {
		// border: 2px solid $gray-300;
		// border-radius: .25rem;

		.toggles {
			width: 100%;

			span {
				width: 25%;
				// background-color: $gray-100;
				border: 1px solid $gray-200;
				border-radius: .25rem .25rem 0 0;
				padding: .25rem 0;
				cursor: pointer;
				transition: .1s background-color ease, .1s border-color ease;

				&:hover {
					background-color: $gray-100;
				}

				&.active {
					background-color: $gray-300;
					border-color: $gray-300;
				}
			}
		}

		.values {
			width: 100%;
			background-color: $gray-200;
			border-radius: 0 0 .25rem .25rem;

			.txns-tab {
				width: 25%;
				padding: .75rem 1rem;
			}
		}
	}
}

.TVChartHeader {
	.hide-chart-container {
		[class^=fa] {
			transition: color .1s ease;
		}

		&:hover {
			[class^=fa] {
				color: $info;
			}
		}
	}
}
