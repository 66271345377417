@import '../../styles/global.scss';

.TokensDropdown {
	.toggle {
		height: 2.4rem;
		width: 100%;
		padding: .25rem .75rem;
		border: 1px solid $gray-200;
		border-radius: .5rem;
		transition: border-color .15s ease;

		&:hover {
			border-color: $gray-400;
		}

		&:focus {
			box-shadow: none;
			border-color: $info;
		}
	}

	.dropdown-menu {
		width: 100%;
	}
}
