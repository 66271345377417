@import '../../../styles/global';

.Position {
	.back-container {
		.back-btn {
			transition: color .15s ease;

			svg {
				transition: margin .15s ease;
			}

			&:hover {
				color: $info;

				svg {
					margin-right: .25rem;
				}
			}
		}
	}

	.main-container {
		.col-infoCards {
			.card {
				.details-box {
					min-height: 28px;

					&:not(:first-child) {
						margin-top: .25rem;
					}
				}
			}

			@include media-breakpoint-down(md) {
				display: flex;
				width: 100%;
				overflow-x: scroll;
				padding-bottom: 1.5rem;
				padding-right: 15px;
				scroll-snap-type: x mandatory;

				.card {
					min-width: 100%;
					max-width: 100%;
					scroll-snap-align: center;

					&:not(:first-child) {
						margin-left: 1rem;
					}
				}
			}
		}
	}
}
