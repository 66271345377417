@import '../../../../styles/global';

.TickChart {
	overflow: hidden;

	.title-container {
		[class^=fa] {
			transition: color .1s ease;
		}

		&:hover {
			[class^=fa] {
				color: $info;
			}
		}
	}

	.chart-container {
		background-color: #191b1f;
		position: relative;

		.chart-zoom-btns {
			position: absolute;
			top: 22px;
			right: 22px;
			padding: 4px;
			border-radius: 8px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 6px;
			color: #fff;

			.zoom-btn {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-weight: 500;
				border-radius: 50%;
				padding: 8px 9px;
				background-color: #2c2f36;
				opacity: 0.9;
				cursor: pointer;

				transition: background-color .15s ease-out;

				&:hover {
					background-color: #414650;
				}
			}
		}
	}

	.CustomToolTip {
	  width: 240px;
		padding: .5rem;

	  background-color: #1F2128;
	  border: 1px solid #2C2F36;
	  border-radius: .25rem;
	  color: $gray-100;
	  font-size: 13px;

	  opacity: 0.75;
	  z-index: 10;

	  .stats {
	  	font-size: .8rem;
	  	color: $gray-300;
	  }
	}

	.CurrentPriceLabel {
		.wrapper {
			margin-top: 12px;
			border-radius: 8px;
			padding: .5rem;
			width: fit-content;

			background-color: $gray-800;
			color: $gray-100;

			span {
				line-height: 1.1;
			}
		}
	}
}
